import styled from '@emotion/styled';
import CopyIcon from '@images/copy-solid.svg';
import Container from '@components/Container';
import { useTheme } from '@emotion/react';
import { isMobile } from 'react-device-detect';

const CustomInputLabel = styled.label(({ theme, style }) => ({
    ...style,
    ...theme.typography.p,
    color: theme.colors.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing.s,
    input: {
        textAlign: `${ isMobile ? 'center' : 'left' }`,
        maxWidth: '70px'
    }
}));

const CustomInput = styled.input(({ theme }) => ({
    ...theme.typography.pBold,
    color: theme.colors.primary.dark,
    border: `1px solid ${theme.colors.common.lightBackground}`,
    borderRadius: theme.borderRadius.s,
    marginTop: theme.spacing.xxs,
    padding: `${theme.spacing.xxxs} ${theme.spacing.xxs}`,
    outline: 'none',
    backgroundColor: theme.colors.common.lightBackground,
    '&:placeholder': {
        color: theme.colors.grey.main,
        opacity: 0.5
    },
    '&:focus, &:active': {
        borderColor: theme.colors.primary.dark
    }
}));

const InfoText = styled.div(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.primary.main,
    marginTop: theme.spacing.xxxs
}));

const Icon = styled.img(({ theme }) => ({
    width: theme.spacing.s,
    height: theme.spacing.s,
    marginTop: theme.spacing.xxs,
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.8
    }
}));

function Input({ label, placeholder, type, infoText, copy, style, ...otherProps }) {
    const theme = useTheme();

    return (
        <div>
            {!copy ? (
                <CustomInputLabel style={style}>
                    {label}
                    <CustomInput type={type} placeholder={placeholder} {...otherProps} />
                    {infoText ? <InfoText>{infoText}</InfoText> : ''}
                </CustomInputLabel>
            ) : (
                <Container gap={theme.spacing.xxs} direction="row">
                    <CustomInput type={type} placeholder={placeholder} {...otherProps} readOnly />
                    <Icon
                        onClick={() => {navigator.clipboard.writeText(otherProps.value);}}
                        src={CopyIcon}
                    />
                </Container>

            )}
        </div>
    );
}

export default Input;
