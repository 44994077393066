import RES from '@utils/resources';
import { FULL_PACKS_TEMPLATE_LIST } from '@utils/globals';

let templateInfo = new Map();

export const getInfo = (templateId) => {
    return templateInfo.get(templateId);
};

export const setInfo = (templateId, info) => {
    return templateInfo.set(templateId, info);
};

setInfo(FULL_PACKS_TEMPLATE_LIST[3], {
    imgHash: 'QmSvnpqb5sdAqCRVhr3JwjwJL2cmQGxXXfoXnPVQkHfZsX',
    name: 'Full Pack',
    isImg: true,
    variant: ''
});
setInfo(FULL_PACKS_TEMPLATE_LIST[4], {
    imgHash: 'QmSFyAWFeGPZ9csKt3tWrQUnq3HV9L38936our1LYbPLnX',
    name: 'Collector\'s Box',
    isImg: true,
    variant: ''
});

