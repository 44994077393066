/* eslint import/extensions: "off" */

import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import Countdown from '@components/Countdown';
import StyledFormModal from '@components/FormModal';
import Grid from '@components/Grid';
import STRINGS from '@utils/strings';
import { SHOW_BUY_PAGE, WAX_SIGN_IN } from '@utils/globals';
import RES from '@utils/resources';
import Container from '@components/Container';
import HowToBuy from '@components/HowToBuy';
import Button from '@components/Button';
import IsReleased from '@components/IsReleased';

import logo from '@images/logo.png';
import coaLogo from '@images/darkpinup-coa-pack.png';

const InfoContainer = styled.div(({ theme, imgToRender }) => ({
    background: `linear-gradient(0deg, rgba(6,2,1,0.7), rgba(6,2,1,0.7)), url(${imgToRender})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl
}));

const Ribbon = styled.div(({ theme }) => ({
    width: '100%',
    color: theme.colors.primary.main,
    backgroundColor: theme.colors.primary.contrastColor,
    fontSize: 'large',
    fontWeight: 'bold',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: theme.spacing.xs
}));

const Headline = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    textAlign: 'center',
    margin: `0 ${theme.spacing.xs}`,
    color: theme.colors.primary.main
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '80ch',
    margin: `0 ${theme.spacing.xs}`,
    color: theme.colors.primary.main
}));

const BlogPost = styled.a(({ theme }) => ({
    color: theme.colors.primary.dark,
    backgroundColor: theme.colors.primary.light,
    padding: `${theme.spacing.xxs} ${theme.spacing.s}`,
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.125rem',
    letterSpacing: 3,
    opacity: 1,
    margin: `${theme.spacing.m}`,
    '&:hover': {
        background: `${theme.colors.primary.main} !important`
    }
}));

const LogoImage = styled.img(({ theme }) => ({
    objectFit: 'contain',
    width: '60vw',
    [theme.mediaQuery.tabletUp]: {
        width: '40vw'
    }
}));

const ActTwo = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    color: theme.colors.common.text,
    textTransform: 'uppercase'

}));

const DarkGradientOverlay = styled.div(() => ({
    backgroundImage: `linear-gradient(rgba(6,2,1,0), rgba(6,2,1,1))`,
    width: '100%',
    height: '500px',
    marginTop: '-500px',
    userSelect: 'none'
}));

function Home() {
    const theme = useTheme();
    const history = useHistory();
    const isReleased = IsReleased();

    const [ showHelpModal, setShowHelpModal ] = useState(false);
    const [ showFormModal, setShowFormModal ] = useState(false);

    const backgroundImages = RES.images.homeBackgrounds;
    const [ imgToRender, setImgToRender ] = useState(backgroundImages[0]);

    useEffect(() => {
        setImgToRender(backgroundImages[Math.floor(Math.random() * backgroundImages.length)]);
    }, []);

    const CustomGrid = {
        marginBottom: theme.spacing.l,
        'img': {
            width: 270,
            objectFit: 'contain'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: '1fr'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
        },
        [theme.mediaQuery.tabletLandscapeUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        },
        [theme.mediaQuery.desktopUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Ribbon>
                {STRINGS.ribbon}
            </Ribbon>
            <InfoContainer imgToRender={imgToRender}>
                <Container direction="column" gap={theme.spacing.s}>
                    <LogoImage alt={STRINGS.campaign} src={logo} />
                    {
                        !isReleased ? (
                            <Container
                                direction="column"
                                gap="24px"
                                margin="32px"
                            >
                                <ActTwo>{STRINGS.actTwo}</ActTwo>
                                <Countdown />
                                <Headline>{STRINGS.releaseDate}</Headline>
                                <Subtext>{STRINGS.releaseTime}</Subtext>
                            </Container>
                        ) : null
                    }
                    <Headline>
                        <a target='_blank' rel='noopener noreferrer'
                            href="https://neftyblocks.com/collection/darkpinup/drops/191297"
                        >
                                Buy City of Angels. Coming August 1st at 10am PST.
                        </a>
                    </Headline>
                    <LogoImage alt="City of Angels Pack" src={coaLogo} />
                </Container>
                <DarkGradientOverlay />
            </InfoContainer>
            <Container
                direction="column"
            >
                <Grid items={RES.samples} style={CustomGrid} />
                <BlogPost href={RES.externalLinks.blog.link} target="_blank" rel="noopener noreferrer">{RES.externalLinks.blog.label}</BlogPost>
            </Container>
            <Container padding={theme.spacing.s}>
                <Button
                    onClick={() => setShowHelpModal(true)}
                    secondary
                >
                    {STRINGS.help}
                </Button>
            </Container>
            { !SHOW_BUY_PAGE && WAX_SIGN_IN ? (
                <Container
                    direction="column"
                    padding={theme.spacing.l}
                    margin={`${theme.spacing.l} 0`}
                    gap={theme.spacing.m}
                    style={{ backgroundColor: theme.colors.primary.dark,
                        color: theme.colors.primary.main
                    }}
                >
                    <Headline>{STRINGS.howToBuyHeadline}</Headline>
                    <HowToBuy />
                </Container>
            ) : null }
            <StyledFormModal
                show={showFormModal}
                onClose={() => setShowFormModal(false)}
                title={STRINGS.formTitle}
                portalId='19612576'
                formId='c61351e2-b65a-451f-9003-f8d5d6d187cd'
            />
            <StyledFormModal
                show={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                title={STRINGS.helpFormTitle}
                portalId='19612576'
                formId='6a9f8c33-f4fb-43fd-8b15-4fd2c0561566'
            />
        </>
    );
}

export default Home;
