import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Container from '@components/Container';
import { useTheme } from '@emotion/react';
import STRINGS from '@utils/strings';
import { IPFS_ENDPOINT } from '@utils/globals';
import Modal from '@components/Modal';
import FormAddress from '@components/FormAddress';
import { withUAL } from 'ual-reactjs-renderer';
import { redeemAssetInformation, transferAsset } from '@api/api';
import ErrorModal from '@components/ErrorModal';
import SuccessModal from '@components/SuccessModal';
import StyledFormModal from '@components/FormModal';
import Button from '@components/Button';

const Headline = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    letterSpacing: '4px',
    color: theme.colors.common.text,
    textTransform: 'uppercase',
    textAlign: 'center',
    [ theme.mediaQuery.mobileOnly ]: {
        ...theme.typography.h2
    }
}));

const Image = styled.img(({ theme }) => ({
    maxWidth: '330px',
    height: '100%',
    objectFit: 'contain',
    [ theme.mediaQuery.mobileOnly ]: {
        maxHeight: '300px'
    }
}));

const Video = styled.video(({ theme }) => ({
    maxWidth: '300px',
    height: '100%',
    objectFit: 'contain',
    [ theme.mediaQuery.mobileOnly ]: {
        maxHeight: '300px'
    }
}));

const Title = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    color: theme.colors.common.text
}));

const userPhysicalInfo = {
    customer: {
        [STRINGS.customerFields.accountName]: "",
        [STRINGS.customerFields.email]: "",
        [STRINGS.customerFields.items]: []
    },
    address: {
        [STRINGS.addressFields.fullName]: "",
        [STRINGS.addressFields.addressLine1]: "",
        [STRINGS.addressFields.addressLine2]: "",
        [STRINGS.addressFields.addressLine3]: "",
        [STRINGS.addressFields.addressLine4]: "",
        [STRINGS.addressFields.city]: "",
        [STRINGS.addressFields.state]: "",
        [STRINGS.addressFields.country]: "",
        [STRINGS.addressFields.zipCode]: ""
    }
};

const userDigitalInfo = {
    customer: {
        [STRINGS.customerFields.accountName]: "",
        [STRINGS.customerFields.items]: []
    },
    address: {
        [STRINGS.customerFields.accountName]: ""
    }
};

function RedeemAsset({ asset, onClose, ual, setSuccessfullyRedeemed }) {
    const theme = useTheme();

    const physicalRedemption = asset.redemption_type === 'physical' ? true : false;

    const [ userInfo, setUserInfo ] = useState(physicalRedemption ? userPhysicalInfo : userDigitalInfo);
    const [ errorInfo, setErrorInfo ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ redeemInformation, setRedeemInformation ] = useState({});
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showHelpModal, setShowHelpModal ] = useState(false);
    const [ redeemCompleted, setRedeemCompleted ] = useState({});

    useEffect(() => {
        updateCustomerInformation();
    }, []);

    const updateAddressInformation = (field, value) => {
        let newUserInfo = { ...userInfo };

        if (field === "email") {
            newUserInfo.customer[field] = value;
        } else {
            newUserInfo.address[field] = value;
        }

        setUserInfo(newUserInfo);
    };

    const updateCustomerInformation = () => {
        let newUserInfo = { ...userInfo };

        newUserInfo.customer[STRINGS.customerFields.accountName] = ual.activeUser.accountName;
        newUserInfo.customer[STRINGS.customerFields.items][0] = { template_id: asset.template.template_id, asset_ids: [asset.asset_id] };

        setUserInfo(newUserInfo);
    };

    function handleErrorMessage(message) {
        if (message.includes("409")) {
            return "Redemption already requested";
        }
        return message;
    }

    const error = (error) => {
        setErrorInfo(handleErrorMessage(error));
        setShowErrorModal(true);
    };

    const onFormValidated = (address) => {
        if (physicalRedemption) {
            redeemAssetInformation(address, 'physical', setRedeemInformation, error);
        } else {
            redeemAssetInformation(address, 'hok', setRedeemInformation, error);
        }
    };

    useEffect(() => {
        if (redeemInformation && Object.keys(redeemInformation).length > 0) {
            if (redeemInformation.memo) {
                transferAsset(ual.activeUser, asset.asset_id, setRedeemCompleted, error, true, redeemInformation.memo);
            } else {
                setShowErrorModal(true);
            }
        }
    }, [redeemInformation]);

    useEffect(() => {
        if (redeemCompleted && Object.keys(redeemCompleted).length > 0) {
            if (redeemCompleted.wasBroadcast) {
                setShowSuccessModal(true);
            } else {
                setShowErrorModal(true);
            }
        }
    }, [redeemCompleted]);

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        onClose();
        setSuccessfullyRedeemed(true);
    };

    const customStyledModal = {
        width: '100%',
        overflowY: 'scroll',
        margin: `${theme.spacing.m} ${theme.spacing.xxs}`,
        [theme.mediaQuery.largeMobileUp]: {
            margin: `${theme.spacing.m} !important`
        }
    };

    const isImage = asset && asset.data.img != null;

    return (
        <Modal show style={customStyledModal} onClose={onClose}>
            <Container
                direction="column"
                gap={theme.spacing.xl}
            >
                <Container direction="column" gap={theme.spacing.xxs}>
                    <Headline>{STRINGS.redemption}</Headline>
                </Container>
                <Container gap={theme.spacing.s} >
                    <Container direction="column" gap={theme.spacing.xs} style={{ maxWidth: '400px' }}>
                        {isImage ? (
                            <Image src={`${IPFS_ENDPOINT}${asset.data.img}`} alt={asset.data.name} />
                        ) : (
                            <Video muted autoPlay loop playsInline >
                                <source src={`${IPFS_ENDPOINT}${asset.data.video}`} type="video/mp4" />
                            </Video>
                        ) }
                        <Title>{asset.data.name}</Title>
                    </Container>
                    <Container style={{ maxWidth: '400px' }}>
                        <FormAddress
                            formValues={userInfo}
                            onAddressChange={updateAddressInformation}
                            onFormValidated={onFormValidated}
                            onError={error}
                            isPhysical={physicalRedemption}
                        />
                    </Container>
                </Container>
            </Container>
            <SuccessModal
                show={showSuccessModal}
                onConfirm={() => handleCloseModal()}
                onClose={() => handleCloseModal()}
                title={STRINGS.successfulRedeem}
            >
                {STRINGS.redeemSuccessMessage}
            </SuccessModal>
            <ErrorModal
                show={showErrorModal}
                onClose={() => setShowErrorModal(false)}
            >
                <Container direction="column" gap={theme.spacing.xs}>
                    {errorInfo ? errorInfo : STRINGS.redeemErrorMessage}
                    <Button
                        onClick={() => setShowHelpModal(true)}
                        secondary
                    >
                        {STRINGS.help}
                    </Button>
                </Container>
            </ErrorModal>
            <StyledFormModal
                show={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                title={STRINGS.helpFormTitle}
                portalId='19612576'
                formId='6a9f8c33-f4fb-43fd-8b15-4fd2c0561566'
            />
        </Modal>
    );
}

export default withUAL(RedeemAsset);