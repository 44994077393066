import React, { useEffect, useState, useRef } from 'react';
import * as GLOBAL_VARS from '@utils/globals';
import { withUAL } from 'ual-reactjs-renderer';
import styled from '@emotion/styled';
import { getBrowserContext } from './globals';
import ROUTES from '@utils/routes';
import Modal from '@components/Modal';

const Iframe = styled.iframe(() => ({
    height: '50vh',
    margin: 'auto',
    textAlign: 'center',
    width: '100%'
}));

function FiatIframe({ ual, show, onClose, templateId, amount, price }) {
    const iframeRef = useRef(null);
    const [ uuid, setUuid ] = useState("");

    useEffect(() => {
        setUuid(getBrowserContext());

        window.addEventListener("message", receivedDataFromIframe, false);
        window.name = "campaign_window";

        return () => {
            window.name = "";
            window.removeEventListener("message", receivedDataFromIframe);
        };
    }, []);

    const sendDataToIframe = () => {
        let data = {
            purchaseInformantion: {
                accountName: ual.activeUser.accountName,
                campaignId: GLOBAL_VARS.COLLECTION_NAME,
                itemAmount: amount,
                itemId: templateId,
                itemPrice: price,
                status_url: `${GLOBAL_VARS.PUBLIC_URL}#${ROUTES.paymenthistory}`,
                browserContext: uuid
            }
        };

        setTimeout(function () {
            iframeRef.current.contentWindow.postMessage(JSON.stringify(data), GLOBAL_VARS.FIAT_IFRAME_URL);
        }, 100);
    };

    const receivedDataFromIframe = (event) => {
        // const data = JSON.parse(event.data);

        if (event.origin === GLOBAL_VARS.FIAT_IFRAME_SERVER) {
            // TODO: review if this is the best approach to redirect the page
            window.location.href = `${GLOBAL_VARS.PUBLIC_URL}#${ROUTES.paymenthistory}`;
        }
    };

    return (
        <Modal show={show} onClose={onClose} style={{ width: 600 }}>
            <Iframe ref={iframeRef} src={GLOBAL_VARS.FIAT_IFRAME_URL} onLoad={sendDataToIframe} />
        </Modal>
    );
}

export default withUAL(FiatIframe);
