import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@emotion/react';
import theme from '@styles/theme';
import GlobalStyles from '@styles/global';
import { WAX_SIGN_IN } from '@utils/globals';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { UALProvider } from 'ual-reactjs-renderer';
import { STRINGS } from '@utils/strings';

const waxChain = {
    chainId: process.env.REACT_APP_WAX_CHAINID,
    rpcEndpoints: [{
        protocol: process.env.REACT_APP_WAX_PROTOCOL,
        host: process.env.REACT_APP_WAX_HOST,
        port: process.env.REACT_APP_WAX_PORT
    }]
};

const appName = STRINGS.campaign;
const anchor = new Anchor([waxChain], { appName: appName });
const waxcloud = new Wax([waxChain], { appName: appName });

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {
                WAX_SIGN_IN ?
                    <UALProvider chains={[waxChain]} authenticators={[ waxcloud, anchor ]} appName={appName} >
                        <App />
                    </UALProvider>
                    :
                    <App />
            }
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
