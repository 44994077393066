import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const ProgressContainer = styled.div(({ theme }) => ({
    background: theme.colors.common.lightBackground,
    borderRadius: '.5rem',
    width: '100%'
}));

const Progress = styled.div(({ theme }) => ({
    height: '10px',
    borderRadius: '1rem',
    display: 'block',
    background: `linear-gradient(90deg,${theme.colors.common.lightBackground},${theme.colors.primary.contrastColor} 34%,${theme.colors.primary.contrastHover} 51%,${theme.colors.common.lightBackground} 68%,${theme.colors.primary.contrastColor})`,
    backgroundSize: '300% 100%',
    animation: `${progressAnimation} 2s linear infinite`
}));

const progressAnimation = keyframes`
    0% {
        background-position: 100%
    }
    100% { 
        background-position: 0
    }
`;

const Title = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    textAlign: 'center',
    color: theme.colors.primary.main,
    marginBottom: theme.spacing.xxs,
    [ theme.mediaQuery.desktopUp ]: {
        margin: ` 0 ${theme.spacing.xs} 0`
    }
}));

function ProgressBar({ percent, title, goalAchieved }) {
    const [ value, setValue ] = useState(0);

    useEffect(() => {
        setValue(percent);
    });

    return (
        <>
            <Title>{`${value}% ${value === 100 ? goalAchieved : title}`}</Title>
            <ProgressContainer>
                <Progress style={{ width: `${value}%` }} />
            </ProgressContainer>
        </>
    );
}

export default ProgressBar;