import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { RELEASE_DATE } from '@utils/globals';
import STRINGS from '@utils/strings';

const CountdownContainer = styled.div(({ theme }) => ({
    ...theme.typography.div,
    color: theme.colors.common.text,
    textAlign: 'center',
    display: 'flex'
}));

const CountdownContainerValue = styled.span(({ theme }) => ({
    ...theme.typography.h1,
    fontWeight: 600,
    [ theme.mediaQuery.mobileOnly ]: {
        ...theme.typography.h3
    }
}));

const CountdownContainerLabel = styled.span(({ theme }) => ({
    ...theme.typography.p
}));

const CountdownLabelValue = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `0 ${theme.spacing.xs}`,
    [ theme.mediaQuery.mobileOnly ]: {
        padding: `0 ${theme.spacing.xxs}`
    }
}));

function Countdown() {
    const [ remainingTime, setRemainingTime ] = useState({});

    let interval = null;

    const updateRemainingTime = () => {
        const difference = RELEASE_DATE.getTime() - Date.now();
        if (difference > 0) {
            setRemainingTime({
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            });
        } else {
            setRemainingTime(null);
            clearInterval(interval);
        }
    };

    useEffect(() => {
        updateRemainingTime();
        interval = setInterval(updateRemainingTime, 1000);
        return () => clearInterval(interval);
    }, []);

    const getNormalizedNumber = (number) => {
        return number < 10 ? `0${number}` : number;
    };

    const getDaysValue = () => {
        return remainingTime.days ?
            remainingTime.days :
            null;
    };

    const getDaysLabel = () => {
        return remainingTime.days ?
            ` ${remainingTime.days !== 1 ? STRINGS.days : STRINGS.day} ` :
            null;
    };

    const getHoursValue = () => {
        return remainingTime.days || remainingTime.hours ?
            getNormalizedNumber(remainingTime.hours) :
            null;
    };

    const getHoursLabel = () => {
        return remainingTime.days || remainingTime.hours ?
            ` ${remainingTime.hours !== 1 ? STRINGS.hours : STRINGS.hour} ` :
            null;
    };

    const getMinutesValue = () => {
        return remainingTime.days || remainingTime.hours || remainingTime.minutes ?
            getNormalizedNumber(remainingTime.minutes) :
            null;
    };

    const getMinutesLabel = () => {
        return remainingTime.days || remainingTime.hours || remainingTime.minutes ?
            ` ${remainingTime.minutes !== 1 ? STRINGS.minutes : STRINGS.minute} ` :
            null;
    };

    const getSecondsValue = () => {
        return remainingTime.days || remainingTime.hours || remainingTime.minutes || remainingTime.seconds ?
            getNormalizedNumber(remainingTime.seconds) :
            null;
    };

    const getSecondsLabel = () => {
        return remainingTime.days || remainingTime.hours || remainingTime.minutes || remainingTime.seconds ?
            ` ${remainingTime.seconds !== 1 ? STRINGS.seconds : STRINGS.second}` :
            null;
    };

    return (
        <>
            {
                remainingTime !== null ?
                    <>
                        <CountdownContainer>
                            {
                                getDaysValue() ?
                                    <CountdownLabelValue>
                                        <CountdownContainerValue>
                                            {getDaysValue()}
                                        </CountdownContainerValue>
                                        <CountdownContainerLabel>
                                            {getDaysLabel()}
                                        </CountdownContainerLabel>
                                    </CountdownLabelValue>
                                    : null
                            }
                            { getHoursValue() ?
                                <CountdownLabelValue>
                                    <CountdownContainerValue>
                                        {getHoursValue()}
                                    </CountdownContainerValue>
                                    <CountdownContainerLabel>
                                        {getHoursLabel()}
                                    </CountdownContainerLabel>
                                </CountdownLabelValue>
                                : null
                            }
                            { getMinutesValue() ?
                                <CountdownLabelValue>
                                    <CountdownContainerValue>
                                        {getMinutesValue()}
                                    </CountdownContainerValue>
                                    <CountdownContainerLabel>
                                        {getMinutesLabel()}
                                    </CountdownContainerLabel>
                                </CountdownLabelValue>
                                : null
                            }
                            { getSecondsValue() ?
                                <CountdownLabelValue>
                                    <CountdownContainerValue>
                                        {getSecondsValue()}
                                    </CountdownContainerValue>
                                    <CountdownContainerLabel>
                                        {getSecondsLabel()}
                                    </CountdownContainerLabel>
                                </CountdownLabelValue>
                                : null
                            }
                        </CountdownContainer>
                    </> : null
            }
        </>
    );
}

export default Countdown;
