import { Route, Redirect, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import Home from '@context/Home';
import News from '@context/News';
import BuyPacks from '@context/Shop/BuyPacks';
import Inventory from '@context/Inventory';
import NotFound from '@context/NotFound';
import PackRips from '@context/PackRips';
import PrivacyPolicy from '@context/PrivacyPolicy';
import License from '@context/License';
import FiatHistory from '@components/Fiat/FiatHistory';
import TermsAndConditions from '@context/TermsAndConditions';
import FAQ from '@context/FAQ';
import Craft from '@context/Craft';
import Resources from '@context/Resources';
import actOne from '@context/ActOne';
import actTwo from '@context/ActTwo';
import VivaPacks from '@context/VivaPacks';
import Leaderboard from '@context/Leaderboard';
import ROUTES from '@utils/routes';
import { SHOW_FAQ, SHOW_NEWS, WAX_SIGN_IN, SHOW_HEADER, SHOW_BUY_PAGE, SHOW_PACK_HISTORY, FIAT_PAYMENT_ENABLED, SHOW_CRAFT } from '@utils/globals';
import { withUAL } from 'ual-reactjs-renderer';
import { withRouter } from 'react-router-dom';

const Container = styled.div(({ theme }) => ({
    width: '100%',
    height: '100%',
    margin: SHOW_HEADER ? `${theme.size.headerHeight} 0px 0px 0px` : 0
}));

function Content({ ual, location, history }) {
    if (WAX_SIGN_IN && !ual.activeUser) {
        if (location.pathname.includes(ROUTES.inventory) ||
            location.pathname.includes(ROUTES.craft) ||
            location.pathname.includes(ROUTES.view)) {
            history.push(ROUTES.home);
        }
    }

    return (
        <Container>
            <Switch>
                <Redirect exact from="/" to={ROUTES.home} />
                <Route path={ROUTES.home} component={Home} />
                { SHOW_NEWS ? <Route path={ROUTES.news} component={News} /> : null }
                { SHOW_BUY_PAGE ? <Route path={ROUTES.buypacks} component={BuyPacks} /> : null }
                { SHOW_FAQ ? <Route path={ROUTES.faq} component={FAQ} /> : null }
                { WAX_SIGN_IN ? <Route path={ROUTES.inventory} component={Inventory} /> : null }
                { WAX_SIGN_IN && SHOW_PACK_HISTORY ? <Route path={ROUTES.packrips} component={PackRips} /> : null }
                { WAX_SIGN_IN && SHOW_CRAFT ? <Route path={ROUTES.craft} component={Craft} /> : null }
                { WAX_SIGN_IN ? <Route path={ROUTES.resources} component={Resources} /> : null }
                { WAX_SIGN_IN && FIAT_PAYMENT_ENABLED && SHOW_BUY_PAGE ? <Route path={ROUTES.paymenthistory} component={FiatHistory} /> : null }
                <Route path={ROUTES.actOne} component={actOne} />
                <Route path={ROUTES.actTwo} component={actTwo} />
                <Route path={ROUTES.vivaPacks} component={VivaPacks} />
                <Route path={ROUTES.leaderboard} component={Leaderboard} />
                <Route path={ROUTES.privacypolicy} component={PrivacyPolicy} />
                <Route path={ROUTES.termsandconditions} component={TermsAndConditions} />
                <Route path={ROUTES.nftlicense} component={License} />
                <Route path={ROUTES.notfound} component={NotFound} />
                <Redirect to={ROUTES.notfound} />
            </Switch>
        </Container>
    );
}

export default WAX_SIGN_IN ? withRouter(withUAL(Content)) : withRouter(Content);
