import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import StyledButton from '@components/Button';
import STRINGS from '@utils/strings';
import { SHOW_HEADER } from '@utils/globals';
import ROUTES from '@utils/routes';
import RES from '@utils/resources';

const InfoContainer = styled.div(({ theme }) => ({
    minHeight: SHOW_HEADER ? `calc(100vh - ${theme.size.headerHeight})` : '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const Label = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    margin: `${theme.spacing.xxl} 0 ${theme.spacing.m} 0`,
    textAlign: 'center',
    color: theme.colors.primary.main
}));

function NotFound() {
    const history = useHistory();

    return (
        <InfoContainer>
            <Label>{STRINGS.notFound}</Label>
            <StyledButton onClick={() => { history.push(ROUTES.home);}}>
                {STRINGS.goToHome}
            </StyledButton>
        </InfoContainer>
    );
}

export default NotFound;
