import Card from './Card';
import { FIAT_TRANSACTION_STATES } from './globals';
import Button from '@components/Button';
import Container from '@components/Container';
import { IPFS_ENDPOINT, SELLING_PACKS_TEMPLATE_LIST } from '@utils/globals';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

const HeaderDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xxs,
    margin: theme.spacing.xxs,
    alignItems: 'center',
    [theme.mediaQuery.tabletLandscapeUp]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

const TemplateContainerDiv = styled.div(({ theme }) => ({
    display: 'block',
    marginTop: theme.spacing.xxs,
    [theme.mediaQuery.tabletLandscapeUp]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)'
    }
}));

const TemplateDiv = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing.m,
    margin: theme.spacing.xs
}));

const TemplateImg = styled.img(({ theme }) => ({
    width: theme.size.templateWidth,
    height: theme.size.templateHeight,
    objectFit: 'contain'
}));

const HeaderItemP = styled.p(({ theme }) => ({
    marginBottom: theme.spacing.xxs
}));

const Label = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    marginBottom: theme.spacing.xxs,
    color: theme.colors.primary.dark
}));

const Info = styled.p(({ theme }) => ({
    ...theme.typography.pBold,
    marginBottom: theme.spacing.xxs,
    color: theme.colors.primary.dark
}));

const Hr = styled.hr(({ theme }) => ({
    border: `1px solid ${theme.colors.primary.dark}`
}));

const Description = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.primary.dark
}));

function FiatTransactionCard({ transaction, packsImages={} }) {
    const theme = useTheme();
    const history = useHistory();

    const customCardStyle = {
        backgroundColor: FIAT_TRANSACTION_STATES[transaction.status].backgroundColor ? FIAT_TRANSACTION_STATES[transaction.status].backgroundColor : 'unset',
        padding: theme.spacing.xxs,
        color: theme.colors.common.text,
        minHeight: 80,
        display: 'block'
    };

    const renderStatus = () => {
        let color = FIAT_TRANSACTION_STATES[transaction.status].color ? FIAT_TRANSACTION_STATES[transaction.status].color : 'unset';
        let status = <b style={{ ...theme.typography.h4, color: color, marginRight: theme.spacing.xxs }}>{FIAT_TRANSACTION_STATES[transaction.status].label}</b>;
        if (transaction.status === FIAT_TRANSACTION_STATES.pay_pending.key && transaction.redirect) {
            status = <>{status} <a href={transaction.redirect}><Button>Approve Payment</Button></a></>;
        }
        return status;
    };

    const renderTemplates = () => {
        return transaction.templates.map((template, index) => {
            const templateObj = SELLING_PACKS_TEMPLATE_LIST.filter(x => `${x}` === template.template_id)[0];
            const img = packsImages[template.template_id] ? packsImages[template.template_id].imgHash : "";
            return templateObj ?
                <TemplateDiv key={index}>
                    <TemplateImg src={`${IPFS_ENDPOINT}${img}`}>
                        {/* <video
                            playsInline
                            muted
                            autoPlay
                            loop
                        >
                            <source
                                src={`${IPFS_ENDPOINT}${img}`}
                                type="video/mp4"
                            />
                        </video> */}
                    </TemplateImg>
                    <Description>{template.qnt} x {templateObj.name}</Description>
                </TemplateDiv>
                : null
            ;
        });
    };

    return (
        <Card style={customCardStyle}>
            <HeaderDiv>
                <Container
                    direction="column"
                >
                    <Label>Transaction</Label>
                    <Info>{transaction.transaction_id}</Info>
                </Container>
                <Container
                    direction="column"
                >
                    <Label>Recipient Account</Label>
                    <Info>{transaction.account_name}</Info>
                </Container>
                <Container direction="column">
                    <HeaderItemP>{renderStatus()}</HeaderItemP>
                    {transaction.detail ? <Description>{transaction.detail}</Description> : null}
                </Container>
            </HeaderDiv>
            <Hr />
            <TemplateContainerDiv>
                {renderTemplates()}
            </TemplateContainerDiv>
            <div />
        </Card>
    );
}

export default FiatTransactionCard;
