import { SHOW_FAQ, SHOW_BUY_PAGE, SHOW_NEWS, WAX_SIGN_IN, SHOW_CRAFT, SHOW_PACK_HISTORY, FIAT_PAYMENT_ENABLED } from '@utils/globals';

const ROUTES = {
    home: '/home',
    buypacks: SHOW_BUY_PAGE ? '/shop' : '/not_found',
    faq: SHOW_FAQ ? '/faq' : '/not_found',
    news: SHOW_NEWS ? '/news': '/not_found',
    inventory: WAX_SIGN_IN ? '/inventory' : '/not_found',
    packrips: WAX_SIGN_IN && SHOW_PACK_HISTORY ? '/pack_rips': '/not_found',
    craft: WAX_SIGN_IN && SHOW_CRAFT ? '/craft': '/not_found',
    view: WAX_SIGN_IN ? '/view' : '/not_found',
    resources: WAX_SIGN_IN ? '/resources' : '/not_found',
    privacypolicy: '/privacy_policy',
    termsandconditions: '/terms_and_conditions',
    nftlicense: '/nft_license',
    notfound: '/not_found',
    paymenthistory: WAX_SIGN_IN && FIAT_PAYMENT_ENABLED && SHOW_BUY_PAGE ? '/payment_history' : '/not_found',
    actOne: '/act_one',
    actTwo: '/act_two',
    vivaPacks: '/viva_packs',
    leaderboard: '/leaderboard'
};

export default ROUTES;
