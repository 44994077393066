import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import STRINGS from '@utils/strings';
import FAQItem from '@components/FAQItem';

const Container = styled.div(({ theme }) => ({
    color: theme.colors.secondaryDark,
    maxWidth: '80ch',
    margin: 'auto',
    padding: `${theme.spacing.l} 0`
}));

function FAQ() {
    const faqItems = STRINGS.faqItems.map((item, index) => <FAQItem key={index} question={item.question} answer={item.answer} />);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Container>
                {faqItems}
            </Container>
        </>
    );
}

export default FAQ;
