import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { getDropInfo, getFiatInformation } from '@api/api';
import {
    PACK_DROPS_LIST,
    SELLING_PACKS_TEMPLATE_LIST,
    WAX_SIGN_IN,
    WAX_PAYMENT,
    FIAT_PAYMENT_ENABLED,
    SINGLE_PROBABILITY,
    WAX,
    FIAT
} from '@utils/globals';
import IsReleased from '@components/IsReleased';
import ErrorModal from '@components/ErrorModal';
import Ribbon from '@components/Ribbon';
import Pack from '@context/Shop/Pack';
import Loading from '@components/Loading';
import STRINGS from '@utils/strings';
import RES from '@utils/resources';
import { withUAL } from 'ual-reactjs-renderer';
import HowToBuy from '@components/HowToBuy';
import { useTheme } from '@emotion/react';
import Container from '@components/Container';
import ProgressBar from '@components/ProgressBar';

import atomicHubLogo from '@images/atomichub.png';
import waxStashLogo from '@images/waxStash.png';
import nftHiveLogo from '@images/nftHive.svg';
import logo from '@images/logo.png';

const SecondaryMarketContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `${theme.spacing.xl} auto`,
    padding: theme.spacing.xs,
    width: 'max-content',
    color: theme.colors.primary.contrastText,
    backgroundColor: theme.colors.primary.main,
    borderRadius: theme.borderRadius.m
}));

const SecondaryMarketLogosContainer = styled.div(({ theme }) => ({
    width: theme.size.secondaryMarketContainerWidth,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
}));

const Wrapper = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: 'auto',
    maxWidth: theme.size.maxContentWidth
}));

const SecondaryMarketImage = styled.img(({ theme }) => ({
    padding: theme.spacing.xs,
    width: theme.size.secondaryMarketLogosWidth,
    objectFit: 'contain'
}));

const Headline = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    textAlign: 'center',
    margin: `${theme.spacing.xs} ${theme.spacing.xxs} `,
    color: theme.colors.primary.main,
    [ theme.mediaQuery.desktopUp ]: {
        margin: ` 0 ${theme.spacing.xs} 0`
    }
}));

const DescriptionTitle = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    textAlign: 'center',
    color: theme.colors.primary.main,
    marginBottom: theme.spacing.xs,
    [ theme.mediaQuery.desktopUp ]: {
        margin: ` 0 ${theme.spacing.xs} 0`
    }
}));

const Subtitle = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text
}));

const PackDetailsTitle = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    textTransform: 'uppercase',
    color: theme.colors.common.text,
    fontWeight: 'bold',
    letterSpacing: '4px',
    marginBottom: theme.spacing.xxs
}));

const PackDetailsSubtitle = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    marginBottom: theme.spacing.xs
}));

const PackDetailsList = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.common.text,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    [ theme.mediaQuery.tabletUp]: {
        maxWidth: 480
    }
}));

const PackDetailItem = styled.li(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${theme.colors.common.lightBackground}`,
    padding: theme.spacing.xxs
}));

const LogoImage = styled.img(({ theme }) => ({
    width: '40vw',
    height: '30vh',
    margin: theme.spacing.xxs,
    objectFit: 'contain',
    userSelect: 'none'
}));

const SoldPercentage = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    color: theme.colors.common.text,
    textAlign: 'center'
}));

const SoldPercentageItems = styled.div(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    marginBottom: theme.spacing.xs,
    'ul': {
        listStyle: 'none',
        textAlign: 'center',
        padding: 0
    }
}));

function BuyPacks({ ual }) {
    const isReleased = IsReleased();
    const theme = useTheme();

    const [ packsInfo, setPacksInfo ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState("");

    useEffect(() =>  {
        window.scrollTo(0, 0);

        const updatePackInfo = (templateId, info, key, index) => {
            if (aux[index]) {
                aux[index].info.set(key, info);
            } else {
                templateId = templateId.toString();
                let map = new Map();
                aux[index] = {
                    templateId: templateId,
                    info: map.set(key, info)
                };
            }

            if (key === WAX) {
                countWax += 1;
            } else {
                countFiat += 1;
            }

            if (checkGotAllInfo()) { setPacksInfo(aux); }
        };

        const checkGotAllInfo = () => {
            let gotWAXInfo = WAX_PAYMENT ? countWax === PACK_DROPS_LIST.length : true;
            let gotFiatInfo = FIAT_PAYMENT_ENABLED ? countFiat === SELLING_PACKS_TEMPLATE_LIST.length : true;
            return gotWAXInfo && gotFiatInfo;
        };

        let countFiat = 0, countWax = 0;
        let isMounted = true;
        let aux = [];
        if (isMounted && WAX_PAYMENT) {
            PACK_DROPS_LIST.forEach((drop_id, index) => {
                getDropInfo(
                    parseInt(drop_id),
                    (info) => { updatePackInfo(info.template_id, info, WAX, index); },
                    (errorMessage) => setErrorMessage(errorMessage)
                );
            });
        }

        if (isMounted && FIAT_PAYMENT_ENABLED) {
            SELLING_PACKS_TEMPLATE_LIST.forEach((template_id, index) => {
                getFiatInformation(
                    parseInt(template_id),
                    (info) => { updatePackInfo(template_id, info, FIAT, index); },
                    (errorMessage) => setErrorMessage(errorMessage)
                );
            });
        }
        return () => { isMounted = false; };
    }, []);

    const isAnySoldOut = () => {
        packsInfo.forEach((value) => {
            return value.info.forEach((value) => {
                if (value.available === 0) {
                    return true;
                }
            });
        });
    };

    const renderSecondaryMarketLinks = () => {
        return isAnySoldOut() ? (
            <SecondaryMarketContainer>
                <h2>{STRINGS.secondaryMarketTitle}</h2>
                <SecondaryMarketLogosContainer>
                    <a href={RES.secondaryMarketLinks.nftHive.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={nftHiveLogo} alt={RES.secondaryMarketLinks.nftHive.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.atomicHub.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={atomicHubLogo} alt={RES.secondaryMarketLinks.atomicHub.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.waxStash.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={waxStashLogo} alt={RES.secondaryMarketLinks.waxStash.label} />
                    </a>
                </SecondaryMarketLogosContainer>
            </SecondaryMarketContainer>
        ): null;
    };

    const renderPacks = () => {
        let packsToRender = [];
        packsInfo.forEach((value, index) => {
            packsToRender.push(<Pack packInfo={value.info} templateId={value.templateId} key={index} />);
        });
        return packsToRender;
    };

    const salesProgress = () => {
        let maxClaimable = [];
        let totalAvailable = [];

        for (const pack in packsInfo) {
            for (const payment of packsInfo[pack].info) {
                const { available, max_claimable } = payment[1];
                maxClaimable.push(max_claimable);
                totalAvailable.push(available);
            }
        }

        const reducer = (previousValue, currentValue) => previousValue + currentValue;
        const totalAvailableToSell = maxClaimable.reduce(reducer, 0);
        const totalSold = totalAvailableToSell - totalAvailable.reduce(reducer, 0);

        const percentage = (totalSold / totalAvailableToSell * 100).toFixed(2);

        return percentage;
    };

    const progressStyle = {
        width: '100%',
        maxWidth: '500px'
    };

    return (
        packsInfo ?
            <>
                {
                    isReleased ? "" : <Ribbon />
                }
                <Wrapper>
                    { renderPacks() }
                </Wrapper>
                {salesProgress() >= '0.1' ? (
                    <Container padding={theme.spacing.l}>
                        <Container direction="column" style={progressStyle}>
                            <ProgressBar title={STRINGS.alreadySold} goalAchieved={STRINGS.soldOut} percent={salesProgress()} />
                        </Container>
                    </Container>
                ) : null }
                <Container direction="column" padding={`${theme.spacing.s} ${theme.spacing.xs}`} >
                    <DescriptionTitle>{STRINGS.roadmap}</DescriptionTitle>
                    {STRINGS.salesProgress.map((entry) => {
                        return (
                            <>
                                <SoldPercentage>{entry.percentage}</SoldPercentage>
                                <SoldPercentageItems>{entry.items}</SoldPercentageItems>
                            </>
                        );
                    })}
                </Container>
                <Container direction="column" padding={`${theme.spacing.xl} ${theme.spacing.xs}`} >
                    <DescriptionTitle>{STRINGS.burntPacks}</DescriptionTitle>
                </Container>
                {
                    SINGLE_PROBABILITY ?
                        <Container
                            direction="column"
                            alignItems="center"
                            padding={`0 ${theme.spacing.xs}`}
                            margin={`0  0 ${theme.spacing.xl} 0`}
                        >
                            <PackDetailsTitle>{STRINGS.probabilities[0].title}</PackDetailsTitle>
                            <PackDetailsSubtitle>Same across packs and boxes</PackDetailsSubtitle>
                            <PackDetailsList>
                                {
                                    STRINGS.probabilities.map((pack) => {
                                        let prob = [];
                                        prob = pack.probabilities.map((line, index) => (
                                            <PackDetailItem key={index}>
                                                <span>
                                                    {line.rarity} { line.note ? <span style={{ ...theme.typography.pTiny, marginRight: theme.spacing.xxs }}> ({line.note}) </span> : null }
                                                </span>
                                                <span>
                                                    {line.probability}
                                                </span>
                                            </PackDetailItem>
                                        ));
                                        return prob;
                                    })
                                }
                            </PackDetailsList>
                        </Container>
                        : null
                }
                { WAX_SIGN_IN ? (
                    <Container
                        direction="column"
                        padding={theme.spacing.l}
                        gap={theme.spacing.m}
                        style={{ backgroundColor: theme.colors.primary.dark,
                            color: theme.colors.primary.main
                        }}
                    >
                        <Headline>{STRINGS.howToBuyHeadline}</Headline>
                        <HowToBuy />
                        <LogoImage alt={STRINGS.campaign} src={logo} />
                    </Container>
                ) : null }
                {isReleased ? renderSecondaryMarketLinks() : null}
                <ErrorModal show={errorMessage !== ""} onClose={() => setErrorMessage("")} >
                    {errorMessage}
                </ErrorModal>
            </>
            :
            <Loading />
    );
}

export default WAX_SIGN_IN ? withUAL(BuyPacks) : BuyPacks;
