import { COLLECTION_NAME, FULL_PACKS_TEMPLATE_LIST } from "@utils/globals";
import logo from '@images/logoCrop.png';

export const RES = {
    images: {
        logo: 'https://images.squarespace-cdn.com/content/v1/5d77a40ac296755dcf2f0f6c/1569713902574-JREMXUCK322K3CT1GBEJ/s%26ublknw.png?format=750w',
        horizontalLogo: `${logo}`,
        atomicHubLogo: '@images/atomichub.png',
        waxStashLogo: '@images/waxStash.png',
        nftHiveLogo: '@images/nftHive.svg',

        homeBackgrounds: [
            'https://darkpinup.nyc3.digitaloceanspaces.com/classic.jpg'
        ],

        act1HomeBackgrounds: [
            'https://darkpinup.nyc3.digitaloceanspaces.com/silhouette.jpg',
            'https://darkpinup.nyc3.digitaloceanspaces.com/vixen.jpg'
        ]
    },

    openingAnimations: [
        {
            template_id: FULL_PACKS_TEMPLATE_LIST[0],
            openingAnimation: 'https://darkpinup.nyc3.digitaloceanspaces.com/darkpinup_PACK_opening_1K.mp4'
        },
        {
            template_id: FULL_PACKS_TEMPLATE_LIST[1],
            openingAnimation: 'https://darkpinup.nyc3.digitaloceanspaces.com/darkpinup_BOX_opening_1200x1200.mp4'
        },
        {
            template_id: FULL_PACKS_TEMPLATE_LIST[2],
            openingAnimation: 'https://darkpinup.nyc3.digitaloceanspaces.com/darkpinup_ember_pack_opening_wsound.webm'
        },
        {
            template_id: FULL_PACKS_TEMPLATE_LIST[3],
            openingAnimation: 'https://darkpinup.nyc3.digitaloceanspaces.com/darkpinup_acttwo_PACK_opening_1K.mp4'
        },
        {
            template_id: FULL_PACKS_TEMPLATE_LIST[4],
            openingAnimation: 'https://darkpinup.nyc3.digitaloceanspaces.com/darkpinup_acttwo_BOX_opening_1K.mp4'
        },
        {
            template_id: FULL_PACKS_TEMPLATE_LIST[5],
            openingAnimation: 'https://cdn.facings.io/viva-pack-opening-animation-darkpinup.mp4'
        },
        {
            template_id: 'craft',
            openingAnimation: 'https://cdn.facings.io/darkpinup_craft_animation.mp4'
        }
    ],

    samples: [
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/base.jpeg',
            caption: 'Base'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/noir.jpeg',
            caption: 'Noir'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/print.jpeg',
            caption: 'Print'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/memento.jpeg',
            caption: 'Memento'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/signature.gif',
            caption: 'Artist Signature'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/golden.gif',
            caption: 'Golden Hour'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/primatallic.gif',
            caption: 'Prismatallic'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/candlelight.gif',
            caption: 'Candlelight'
        },
        // {
        //     srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/guaranteed-candlelight.jpeg',
        //     caption: 'Guaranteed Special Candlelight insert'
        // },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/digital-paiting.jpeg',
            caption: 'Digital Paintings'
        }
    ],

    samplesAct1: [
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/1_base.jpg',
            caption: 'Base'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2_noir.jpg',
            caption: 'Noir'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/5_artist-signature.mp4',
            caption: 'Artist Signature',
            type: 'video'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/6_prismatallic.mp4',
            caption: 'Prismatallic',
            type: 'video'
        },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/7_model-mark.mp4',
            caption: 'Model Mark',
            type: 'video'
        },
        // {
        //     srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/2-rainbow/guaranteed-candlelight.jpeg',
        //     caption: 'Guaranteed Special Candlelight insert'
        // },
        {
            srcImg: 'https://darkpinup.nyc3.digitaloceanspaces.com/8_candlelight.mp4',
            caption: 'Candlelight',
            type: 'video'
        }
    ],

    act1Packs: [
        {
            srcImg: 'QmbMCBdr41yaPqo5YbjXt9uTLKjGqThxfwdcS3WcanwcrB',
            caption: 'Full Pack',
            quantity: '9,900 Sold on WAX',
            description: 'Full Pack of 5 NFTs for $7.75'
        },
        {
            srcImg: 'QmRtQVWG6acCtfhHfcxJgfp3prU8N7HpdCPuvoapMYU9LV',
            caption: "Collector's Box",
            quantity: '1,980 Sold on WAX',
            description: 'Collector’s Box of 30 NFTs for $40'
        }
    ],

    act2Packs: [
        {
            srcImg: 'QmSvnpqb5sdAqCRVhr3JwjwJL2cmQGxXXfoXnPVQkHfZsX',
            caption: 'Full Pack',
            quantity: '1,744 Sold for $7,00',
            description: 'Full Pack'
        },
        {
            srcImg: 'QmSFyAWFeGPZ9csKt3tWrQUnq3HV9L38936our1LYbPLnX',
            caption: "Collector's Box",
            quantity: '837 sold for $40.00',
            description: 'Collector’s Box'
        }
    ],

    vivaPacks: [
        {
            srcImg: 'QmVcSoCNW6WovdoGmVh3h795DxJtWygSD2ZFjrdNJz8JUz',
            caption: 'Viva Pack',
            description: 'Live July 21st'
        }
    ],

    vivaPacksSamples: [
        {
            srcImg: 'https://darkpinup.mypinata.cloud/ipfs/QmREWwSfUNwTXEQzUnMS3D7Eb8jMtaVHDQdLTzzCL44Rsx',
            caption: 'Base'
        },
        {
            srcImg: 'https://darkpinup.mypinata.cloud/ipfs/QmVTqgtTCDLUEKwqNokFrcMKymh5VwNULu2FJQgYj62ebD',
            caption: 'Neon',
            type: 'video'
        }
    ],

    learnMoreAboutVivaPack: {
        href: 'https://facings.medium.com/viva-packs-are-coming-5541f58896a',
        label: 'Learn more about Viva Packs'
    },

    secondaryMarketLinks: {
        atomicHub: {
            label: "AtomicHub",
            link: `https://wax.atomichub.io/market?collection_name=${COLLECTION_NAME}`
        },
        atomicHubInventory: {
            label: "AtomicHub Inventory",
            link: `https://wax.atomichub.io/profile/{0}?collection_name=${COLLECTION_NAME}`
        },
        atomicHubEntireCollection: {
            label: "AtomicHub The Strange and Unusual entire Collection",
            link: `https://wax.atomichub.io/tools/book/${COLLECTION_NAME}/{0}`
        },
        waxStash: {
            label: "WAXStash",
            link: `https://www.waxstash.com/markets?collection_name=${COLLECTION_NAME}`
        },
        nftHive: {
            label: "NFTHIVE",
            link: `https://nfthive.io/collection/${COLLECTION_NAME}`
        }
    },

    externalLinks: {
        wcw: {
            label: "Wax Cloud Wallet",
            link: "https://all-access.wax.io"
        },
        wax: {
            label: "Powered by WAX",
            link: "https://wax.io"
        },
        facings: {
            label: "Built by FACINGS",
            link: "https://facings.io"
        },
        facingsTelegram: {
            label: "FACINGS telegram group",
            link: "https://t.me/facings"
        },
        facingsDiscord: {
            label: "FACINGS discord channel",
            link: "https://discord.gg/mW2654Nw3Z"
        },
        facingsTwitter: {
            label: "FACINGS twitter",
            link: "https://twitter.com/FACINGSofficial"
        },
        atomicAssets: {
            label: "Built with Atomic Assets",
            link: "https://wax.atomichub.io/"
        },
        instagram: {
            label: "Lars kommienezuspadt Instagram Account",
            link: "https://www.instagram.com/kommienezuspadt/"
        },
        blog: {
            label: "Learn more about the collection",
            link: "https://facings.medium.com/dark-pinup-2-releases-november-30th-c31f24ecf197"
        }
    }
};

export default RES;
