/* eslint import/extensions: "off" */

import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import StyledFormModal from '@components/FormModal';
import Grid from '@components/Grid';
import STRINGS from '@utils/strings';
import { SHOW_BUY_PAGE, WAX_SIGN_IN, IPFS_ENDPOINT } from '@utils/globals';
import RES from '@utils/resources';
import Container from '@components/Container';
import HowToBuy from '@components/HowToBuy';
import Button from '@components/Button';

import atomicHubLogo from '@images/atomichub.png';
import waxStashLogo from '@images/waxStash.png';
import nftHiveLogo from '@images/nftHive.svg';
import logo from '@images/logo.png';

const InfoContainer = styled.div(({ theme, imgToRender }) => ({
    background: `linear-gradient(0deg, rgba(6,2,1,0.7), rgba(6,2,1,0.7)), url(${imgToRender})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl
}));

const Headline = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    textAlign: 'center',
    margin: `0 ${theme.spacing.xs}`,
    color: theme.colors.primary.main
}));

const LogoImage = styled.img(({ theme }) => ({
    objectFit: 'contain',
    width: '60vw',
    [theme.mediaQuery.tabletUp]: {
        width: '40vw'
    }
}));

const DarkGradientOverlay = styled.div(() => ({
    backgroundImage: `linear-gradient(rgba(6,2,1,0), rgba(6,2,1,1))`,
    width: '100%',
    height: '500px',
    marginTop: '-500px',
    userSelect: 'none'
}));

const SecondaryMarketTitle = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.colors.common.text,
    textAlign: 'center'
}));

const SecondaryMarketImage = styled.img(({ theme }) => ({
    padding: theme.spacing.xs,
    width: theme.size.secondaryMarketLogosWidth,
    objectFit: 'contain'
}));

const Image = styled.img(({ theme }) => ({
    height: '50vh',
    maxHeight: '600px',
    margin: `${theme.spacing.xl} 0 ${theme.spacing.xs}`,
    objectFit: 'contain',
    [ theme.mediaQuery.mobileOnly ]: {
        maxHeight: '300px'
    }
}));

const PackDetailsTitle = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    textTransform: 'uppercase',
    color: theme.colors.common.text,
    fontWeight: 'bold',
    letterSpacing: '4px',
    marginBottom: theme.spacing.xxs
}));

const PackDetailsSubtitle = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    marginBottom: theme.spacing.xs
}));

const PackDetailsList = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.common.text,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    [ theme.mediaQuery.tabletUp]: {
        maxWidth: 480
    }
}));

const PackDetailItem = styled.li(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${theme.colors.common.lightBackground}`,
    padding: theme.spacing.xxs
}));

const PackDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    fontWeight: 'bold'
}));

function Home() {
    const theme = useTheme();

    const [ showHelpModal, setShowHelpModal ] = useState(false);

    const backgroundImages = RES.images.act1HomeBackgrounds;
    const [ imgToRender, setImgToRender ] = useState(backgroundImages[0]);

    useEffect(() => {
        setImgToRender(backgroundImages[Math.floor(Math.random() * backgroundImages.length)]);
    }, []);

    const CustomGrid = {
        marginBottom: theme.spacing.l,
        'img': {
            width: 270,
            objectFit: 'contain'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: '1fr'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
        },
        [theme.mediaQuery.tabletLandscapeUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        },
        [theme.mediaQuery.desktopUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <InfoContainer imgToRender={imgToRender}>
                <Container direction="column">
                    <LogoImage alt={STRINGS.campaign} src={logo} />
                    <div style={{ margin: theme.spacing.m }}>
                        <a href={RES.secondaryMarketLinks.atomicHubEntireCollection.link.replace("{0}", 'darkpinup')} target='_blank' rel='noreferrer noopener'>
                            <Button secondary>
                                {STRINGS.viewEntireCollection}
                            </Button>
                        </a>
                    </div>
                </Container>
                <DarkGradientOverlay />
            </InfoContainer>
            <Container
                direction="column"
            >
                <Grid items={RES.samplesAct1} style={CustomGrid} />
            </Container>
            <Container  direction="row" gap={theme.spacing.xxl} padding={theme.spacing.l}>
                { RES.act1Packs.map((pack, index) => (
                    <Container key={index} direction="column" gap={theme.spacing.xs} style={{ maxWidth: '500px' }}>
                        <Image src={`${IPFS_ENDPOINT}${pack.srcImg}`} alt={pack.caption} />
                        <Container direction="column" gap={theme.spacing.xxs}>
                            <PackDescription>{pack.description}</PackDescription>
                            <PackDescription>{pack.quantity}</PackDescription>
                        </Container>
                    </Container>
                )) }
            </Container>
            <Container
                direction="column"
                alignItems="center"
                padding={`0 ${theme.spacing.xs}`}
                margin={`${theme.spacing.xl} 0`}
            >
                <PackDetailsTitle>{STRINGS.probabilities[0].title}</PackDetailsTitle>
                <PackDetailsSubtitle>{STRINGS.actOneProbabilities[0].subtitle}</PackDetailsSubtitle>
                <PackDetailsList>
                    {
                        STRINGS.actOneProbabilities.map((pack) => {
                            let prob = [];
                            prob = pack.probabilities.map((line, index) => (
                                <PackDetailItem key={index}>
                                    <span>
                                        {line.rarity} { line.note ? <span style={{ ...theme.typography.pTiny, marginRight: theme.spacing.xxs }}> ({line.note}) </span> : null }
                                    </span>
                                    <span>
                                        {line.probability}
                                    </span>
                                </PackDetailItem>
                            ));
                            return prob;
                        })
                    }
                </PackDetailsList>
            </Container>
            <Container direction="column" gap={theme.spacing.xs} padding={theme.spacing.xxl}>
                <SecondaryMarketTitle>{STRINGS.act1SecondaryMarketTitle}</SecondaryMarketTitle>
                <Container direction="row" gap={theme.spacing.xs}>
                    <a href={RES.secondaryMarketLinks.nftHive.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={nftHiveLogo} alt={RES.secondaryMarketLinks.nftHive.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.atomicHub.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={atomicHubLogo} alt={RES.secondaryMarketLinks.atomicHub.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.waxStash.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={waxStashLogo} alt={RES.secondaryMarketLinks.waxStash.label} />
                    </a>
                </Container>
            </Container>
            <Container padding={theme.spacing.s}>
                <Button
                    onClick={() => setShowHelpModal(true)}
                    secondary
                >
                    {STRINGS.help}
                </Button>
            </Container>
            { !SHOW_BUY_PAGE && WAX_SIGN_IN ? (
                <Container
                    direction="column"
                    padding={theme.spacing.l}
                    margin={`${theme.spacing.l} 0`}
                    gap={theme.spacing.m}
                    style={{ backgroundColor: theme.colors.primary.dark,
                        color: theme.colors.primary.main
                    }}
                >
                    <Headline>{STRINGS.howToBuyHeadline}</Headline>
                    <HowToBuy />
                </Container>
            ) : null }
            <StyledFormModal
                show={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                title={STRINGS.helpFormTitle}
                portalId='19612576'
                formId='6a9f8c33-f4fb-43fd-8b15-4fd2c0561566'
            />
        </>
    );
}

export default Home;
