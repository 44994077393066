import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Button from '@components/Button';
import Container from '@components/Container';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import MultiSelect from '@components/MultiSelect';
import { IPFS_ENDPOINT } from '@utils/globals';

const Component = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.xs,
    alignItems: 'center',
    minWidth: '340px'
}));

const Image = styled.img(() => ({
    maxHeight: '160px',
    minHeight: '140px',
    width: '94px',
    objectFit: 'contain'
}));

const Video = styled.video(() => ({
    maxHeight: '160px',
    width: '94px',
    objectFit: 'contain'
}));

const ComponentTitle = styled.p(({ theme }) => ({
    ...theme.typography.p,
    fontWeight: '600',
    color: theme.colors.common.text
}));

const ComponentVariant = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    fontWeight: '400',
    color: theme.colors.common.text
}));

const SelectContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xxs,
    width: '100%'
}));

const SelectDescription = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    fontSize: 16,
    color: theme.colors.common.text
}));

const SelectDescriptionNumber = styled.span(({ theme }) => ({
    ...theme.typography.pTiny,
    fontSize: 16,
    fontWeight: 600,
    color: theme.colors.common.text
}));

function CraftRecipeItem({ component, templateId, selectedItems, updateRecipeItem }) {
    const theme = useTheme();
    const history = useHistory();

    const [ selectedOptions, setSelectedOptions ] = useState([]);

    const isPack = component.variant === "Collector's Box" || component.variant === "Full Pack" || component.variant === "Ember Pack";

    useEffect(() => {
        updateRecipeItem(templateId, selectedOptions);
    }, [selectedOptions]);

    const BuyButtonStyle = {
        fontSize: '14px',
        lineHeight: '140%',
        padding: `${theme.spacing.xxs} ${theme.spacing.s}`
    };

    return (
        <Component>
            {
                component.isImg ?
                    <Image src={`${IPFS_ENDPOINT}${component.imgHash}`} alt={component.name} />
                    :
                    <Video muted autoPlay loop playsInline width='100%'>
                        <source src={`${IPFS_ENDPOINT}${component.imgHash}`} type="video/mp4" />
                    </Video>
            }
            <SelectContainer>
                <Container
                    direction="column"
                    alignItems="flex-start"
                >
                    <ComponentTitle>{component.name}{isPack && ' - Wrapper'}</ComponentTitle>
                    <ComponentVariant>{component.variant !== 'X-Ray' ? component.variant : null}</ComponentVariant>
                </Container>
                {
                    component.assets.length >= component.quantity ?
                        (
                            <>
                                <MultiSelect values={selectedItems} options={component.assets} limit={component.quantity} setSelectedOptions={setSelectedOptions} />
                                {selectedOptions.length !== component.quantity ? <SelectDescription>{"Select "}<SelectDescriptionNumber>{component.quantity - selectedOptions.length}</SelectDescriptionNumber>{` more ${component.quantity - selectedOptions.length === 1 ? "asset" : "assets"}`}</SelectDescription> : null }
                            </>
                        ) :
                        (
                            <>
                                <Button style={BuyButtonStyle} onClick={() => history.push(ROUTES.buypacks)}>{STRINGS.craftBuyPacks}</Button>
                                {selectedOptions.length !== component.quantity ? <SelectDescription>{"Need "}<SelectDescriptionNumber>{component.quantity - selectedOptions.length}</SelectDescriptionNumber>{` more ${component.quantity - selectedOptions.length === 1 ? "asset" : "assets"}`}</SelectDescription> : null }
                            </>
                        )
                }
            </SelectContainer>
        </Component>
    );
}

export default CraftRecipeItem;
