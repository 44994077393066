import { keyframes } from '@emotion/react';
import STRINGS from '@utils/strings';
import styled from '@emotion/styled';

const Spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoadingContainer = styled.div(({ theme }) => ({
    paddingTop: theme.spacing.m,
    display: 'block',
    textAlign: 'center',
    justifyContent: 'center'
}));

const LoadingSpinner = styled.div(({ theme }) => ({
    width: theme.size.loadingWidth,
    height: theme.size.loadingHeight,
    margin: 'auto',
    border: `${theme.size.loadingBorder} solid ${theme.colors.common.lightBackground}`,
    borderTop: `${theme.size.loadingBorder} solid ${theme.colors.primary.contrastColor}`,
    borderRadius: '50%',
    animation: `${Spin} 1.5s linear infinite`
}));

const LoadingCaption = styled.h1(({ theme }) => ({
    marginTop: theme.spacing.xs,
    color: theme.colors.common.text
}));

function Loading() {
    return (
        <LoadingContainer>
            <LoadingSpinner />
            {/* <LoadingCaption>{STRINGS.loading}</LoadingCaption> */}
        </LoadingContainer>
    );
}

export default Loading;
