import { getScoreboard } from "@api/api";
import Container from "@components/Container";
import Loading from "@components/Loading";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { IPFS_ENDPOINT, WAX_SIGN_IN } from "@utils/globals";
import RES from "@utils/resources";
import STRINGS from "@utils/strings";
import { useEffect, useState } from "react";
import { withUAL } from "ual-reactjs-renderer";

const Headline = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    color: theme.colors.primary.main
}));

const Image = styled.img(({ theme }) => ({
    height: "50vh",
    maxHeight: "600px",
    width: "100%",
    objectFit: "contain",
    [theme.mediaQuery.mobileOnly]: {
        maxHeight: "300px"
    }
}));

const PointsTitle = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.colors.common.text,
    fontWeight: "bold",
    letterSpacing: "4px",
    textTransform: "uppercase"
}));

const PointsDescription = styled.p(({ theme }) => ({
    fontSize: "24px",
    lineHeight: "130%",
    fontWeight: 400,
    color: theme.colors.common.text,
    letterSpacing: "2px"
}));

const PointsList = styled.ul(({ theme }) => ({
    fontSize: "20px",
    lineHeight: "160%",
    fontWeight: 400,
    color: theme.colors.common.text,
    listStyle: "none",
    textAlign: "left",
    padding: 0
}));

const PointDetails = styled.li(({ theme }) => ({
    display: "flex",
    gap: theme.spacing.xxl,
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: `1px solid ${theme.colors.common.lightBackground}`,
    padding: `${theme.spacing.xs} ${theme.spacing.xxs}`,
    "span:last-of-type": {
        whiteSpace: "nowrap"
    }
}));

const PackDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    fontWeight: "bold"
}));

function Leaderboard({ ual }) {
    const theme = useTheme();

    const [ leaderboard, setLeaderboard ] = useState([]);

    useEffect(() => {
        getScoreboard(setLeaderboard);
    }, []);

    const columns = { id: "#", user: "user", points: "points" };

    leaderboard.sort(function (a, b) {
        return b.loyalty_points - a.loyalty_points;
    });

    let userInformation;
    if (ual.activeUser) {
        userInformation = leaderboard.find(
            (leaderboard) => leaderboard.user === ual.activeUser.accountName
        );
    }

    const ContainerStyle = {
        margin: "auto",
        maxWidth: "1000px",
        [theme.mediaQuery.largeMobileUp]: {
            padding: `${theme.spacing.xxl} 0`
        },
        [theme.mediaQuery.mobileOnly]: {
            padding: `${theme.spacing.xl} 0 ${theme.spacing.xs} 0`
        }
    };

    return (
        <Container
            direction="column"
            alignItems="center"
            padding={theme.spacing.xxl}
        >
            {leaderboard.length === 0 ? (
                <Loading />
            ) : (
                <>
                    <Headline>{STRINGS.vivaLeaderboard.headline}</Headline>
                    <Container
                        direction="column"
                        alignItems="center"
                        style={{ marginTop: `${theme.spacing.xl}` }}
                        gap={theme.spacing.m}
                    >
                        <Container direction="column" gap={theme.spacing.xxs}>
                            <PointsTitle>{STRINGS.vivaLeaderboard.title}</PointsTitle>
                            <PointsTitle>{STRINGS.vivaLeaderboard.description}</PointsTitle>
                        </Container>
                        <Container direction="row" style={ContainerStyle}>
                            <Container direction="row" gap={theme.spacing.xxl}>
                                {RES.vivaPacks.map((pack, index) => (
                                    <Container
                                        key={index}
                                        direction="column"
                                        gap={theme.spacing.xs}
                                        style={{ maxWidth: "500px" }}
                                    >
                                        <Image
                                            src={`${IPFS_ENDPOINT}${pack.srcImg}`}
                                            alt={pack.caption}
                                        />
                                        <Container direction="column" gap={theme.spacing.xxs}>
                                            <PackDescription>{pack.description}</PackDescription>
                                        </Container>
                                    </Container>
                                ))}
                            </Container>
                            <Container
                                direction="column"
                                alignItems="center"
                                padding={`0 ${theme.spacing.xs}`}
                                margin={`${theme.spacing.xl} 0`}
                                gap={theme.spacing.m}
                            >
                                <Container direction="column" gap={theme.spacing.xxs}>
                                    <PointsTitle>
                                        {STRINGS.vivaLeaderboard.earnPointsBy}
                                    </PointsTitle>
                                </Container>
                                <PointsList>
                                    {STRINGS.vivaPacksPage.pointsList.map((point, index) => {
                                        return (
                                            <PointDetails key={index}>
                                                <span>{point.description}</span>
                                                <span>{point.points}</span>
                                            </PointDetails>
                                        );
                                    })}
                                </PointsList>
                                {ual.activeUser ? (
                                    <PointsDescription>{`${
                                        STRINGS.vivaLeaderboard.earnedPoints
                                    } ${
                                        userInformation && userInformation.loyalty_points
                                            ? userInformation.loyalty_points
                                            : "0"
                                    }`}</PointsDescription>
                                ) : null}
                            </Container>
                        </Container>
                    </Container>
                </>
            )}
        </Container>
    );
}

export default WAX_SIGN_IN ? withUAL(Leaderboard) : Leaderboard;
